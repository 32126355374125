import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Helmet from "react-helmet";

import styles from "./new-grid.module.css";
import BlogPostPreview from "./blog-post-preview";



const NewGrid = () => (

<div class="parent">
<div class="div1"> </div>
<div class="div2"> </div>
<div class="div3"> </div>
</div> 
);
export default NewGrid;


<div className={styles.root}>



    </div>
